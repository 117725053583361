import { Link } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { SHOP_LIST_PATH } from '../../../constants/constants'
import SideNavMenuItems from '../../molecules/Shop/SideNavMenuItems'
import WholesaleInfo from '../../molecules/Site/WholesaleInfo'
import { useUserContext } from '../../../context/user/UserProvider'
import { useProductContext } from '../../../context/products/ProductProvider'
import history from '../../../context/history'

interface IWholesaleOffcanvasProps {
  show: boolean
  handleClose: () => void
}

const WholesaleOffcanvas: React.FC<IWholesaleOffcanvasProps> = ({
  show,
  handleClose,
}) => {
  const { isDarkMode } = useUserContext()
  const { setPage } = useProductContext()

  const onClickQuickLink = (to: string) => {
    setPage(1)
    history.push(to)

    handleClose()
  }

  return (
    <Offcanvas show={show} onHide={handleClose} scroll={true}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Link
            to={`/${SHOP_LIST_PATH}`}
            id={`/${SHOP_LIST_PATH}`}
            onClick={() => {
              onClickQuickLink(`/${SHOP_LIST_PATH}`)
            }}
            className="text-decoration-none"
          >
            <div
              className={`pt-2 pb-2 ms-2 ps-2 fs-5 fw-bold ${
                isDarkMode ? 'text-light' : 'text-black'
              }`}
            >
              Wholesale Fireworks
            </div>
          </Link>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <SideNavMenuItems handleClick={handleClose} />
        <WholesaleInfo />
        <div className="ps-3 mt-4">
          <Link
            to="76fireworks.com/contact"
            target="_blank"
            onClick={handleClose}
          >
            Contact Us
          </Link>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default WholesaleOffcanvas
