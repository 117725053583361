import React from 'react'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import { Accordion } from 'react-bootstrap'
import { SHOP_LIST_PATH } from '../../../constants/constants'
import RwIcon from '../RwIcon'

interface ProLineNavSectionProps {
  title: string
  data?: any[]
  queryType?: string // Only needed for legacy paths (categories, brands, lists)
  isDarkMode: boolean
  onClickQuickLink: (e: any) => void
  useQueryParams?: boolean // Determines if we should use query parameters
}

const ProLineNavSection: React.FC<ProLineNavSectionProps> = ({
  title,
  data,
  queryType,
  isDarkMode,
  onClickQuickLink,
  useQueryParams = false, // Default to query-based URLs
}) => (
  <Accordion
    flush
    className={`hamburger-menu ${isDarkMode ? 'dm-accordion-primary' : ''}`}
  >
    <div className="border-bottom">
      <Accordion.Item eventKey={title} className="border-0">
        <Accordion.Header>
          <div className="fw-bold fs-5">{title}</div>
        </Accordion.Header>
        <Accordion.Body>
          <Nav defaultActiveKey="/home" className="flex-column">
            {data?.map((item: any, index: number) => {
              // Determine final URL format
              const newUrl = useQueryParams
                ? `/${SHOP_LIST_PATH}/?${queryType}=${item.value}` // Query-based URL
                : `/${SHOP_LIST_PATH}/${queryType}/${item.value}` // Path-based URL for legacy filters

              return (
                <Nav.Link
                  key={item.id}
                  className={`ms-2 ${
                    index !== 0 ? 'border-top border-1' : ''
                  } ${isDarkMode ? 'text-light' : 'text-primary'}`}
                  as={Link}
                  to={newUrl}
                  id={newUrl}
                  onClick={onClickQuickLink}
                  data-bs-value={item.value}
                >
                  {item.label}{' '}
                  {item.icon && (
                    <RwIcon icon={`${item.icon}${isDarkMode ? 'Dm' : ''}`} />
                  )}
                </Nav.Link>
              )
            })}
          </Nav>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  </Accordion>
)

export default ProLineNavSection
