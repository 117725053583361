import React from 'react'

// react-icons imports for consts
import {
  BsFileEarmarkPdf,
  BsFileEarmarkExcel,
  BsTag,
  BsTags,
  BsFillBookmarksFill,
  // BsX, //unused
  BsXCircleFill,
  BsFillArrowUpRightSquareFill,
  // BsXLg, //unused alternative
  BsEye,
  BsEyeSlash,
} from 'react-icons/bs'
import {
  FaBarcode,
  FaBars,
  FaTruck,
  FaAddressCard,
  // FaRegAddressCard, //unused alternative
  FaKey,
  FaSignOutAlt,
  FaUser,
  FaRegMinusSquare,
  FaArrowCircleDown,
  FaMinus,
  FaPlus,
  FaSearch,
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaTools,
  FaLinkedin,
} from 'react-icons/fa'
import {
  MdMarkEmailRead,
  MdOutlineOndemandVideo,
  MdSpeed,
} from 'react-icons/md'
import { GiFishbone } from 'react-icons/gi'
import { RiFileTextFill } from 'react-icons/ri'
import { RiFileDownloadFill } from 'react-icons/ri'

import { IoWarningOutline } from 'react-icons/io5' // triangle
import CdnImg from '../organisms/Common/CdnImg'
import { useUserContext } from '../../context/user/UserProvider'

interface IRwIcon {
  icon: string
  furtherIconCss?: string | undefined
  [x: string]: any
}

const RwIcon: React.FC<IRwIcon> = ({ icon, furtherIconCss, ...rest }) => {
  const { isDarkMode } = useUserContext()

  let rwIconComponent = <></>
  switch (icon) {
    case 'InstagramIcon':
      rwIconComponent = <FaInstagram className={furtherIconCss} />
      break
    case 'FacebookIcon':
      rwIconComponent = <FaFacebook className={furtherIconCss} />
      break
    case 'YoutubeIcon':
      rwIconComponent = <FaYoutube className={furtherIconCss} />
      break
    case 'TiktokIcon':
      rwIconComponent = <FaTiktok className={furtherIconCss} />
      break
    case 'LinkedinIcon':
      rwIconComponent = <FaLinkedin className={furtherIconCss} />
      break
    case 'PdfIcon':
      rwIconComponent = (
        <BsFileEarmarkPdf
          className={`${furtherIconCss} ${
            isDarkMode ? 'text-light' : 'pdf_red'
          }`}
        />
      )
      break
    case 'ExcelIcon':
      rwIconComponent = (
        <BsFileEarmarkExcel
          className={`${furtherIconCss} ${
            isDarkMode ? 'text-light' : 'excel_green'
          }`}
        />
      )
      break
    case 'BarcodeIcon':
      rwIconComponent = <FaBarcode className={`${furtherIconCss}`} />
      break
    case 'TagIcon':
      rwIconComponent = (
        <BsTag
          className={`${furtherIconCss} ${
            isDarkMode ? 'text-light' : 'text-primary'
          }`}
        />
      )
      break
    case 'TagsIcon':
      rwIconComponent = (
        <BsTags
          className={`${furtherIconCss} ${
            isDarkMode ? 'text-light' : 'text-primary'
          }`}
        />
      )
      break
    case 'HamburgerIcon':
      rwIconComponent = <FaBars {...rest} />
      break
    case 'TruckIcon':
      rwIconComponent = <FaTruck {...rest} />
      break
    case 'ExternalLinkIcon':
      rwIconComponent = <BsFillArrowUpRightSquareFill {...rest} />
      break
    case 'ToolsIcon':
      rwIconComponent = <FaTools {...rest} />
      break
    case 'BookmarkIcon':
      rwIconComponent = <BsFillBookmarksFill {...rest} />
      break
    case 'AddressCardIcon':
      rwIconComponent = <FaAddressCard {...rest} />
      break
    case 'KeyIcon':
      rwIconComponent = <FaKey {...rest} />
      break
    case 'PreferencesIcon':
      rwIconComponent = <MdMarkEmailRead {...rest} />
      break
    case 'VideoIcon':
      rwIconComponent = <MdOutlineOndemandVideo {...rest} />
      break
    case 'RushIcon':
      rwIconComponent = <MdSpeed {...rest} />
      break
    case 'DocumentIcon':
      rwIconComponent = <RiFileTextFill {...rest} />
      break
    case 'DocumentDownloadIcon':
      rwIconComponent = <RiFileDownloadFill {...rest} />
      break
    case 'SignOutIcon':
      rwIconComponent = <FaSignOutAlt {...rest} />
      break
    case 'UserIcon':
      rwIconComponent = <FaUser {...rest} />
      break
    case 'RemoveIcon':
      rwIconComponent = (
        <FaRegMinusSquare className={`${furtherIconCss} text-warning`} />
      )
      break
    case 'DownloadIcon':
      rwIconComponent = (
        <FaArrowCircleDown className={`${furtherIconCss} text-primary`} />
      )
      break
    // case 'XMarkIcon':
    //   rwIconComponent = <BsX {...rest} />
    //   break
    case 'CircleXMarkIcon':
      rwIconComponent = <BsXCircleFill {...rest} />
      break
    case 'EyeIcon':
      rwIconComponent = <BsEye {...rest} />
      break
    case 'EyeIconHide':
      rwIconComponent = <BsEyeSlash {...rest} />
      break
    case 'MinusIcon':
      rwIconComponent = <FaMinus className={`${furtherIconCss} text-white`} />
      break
    case 'PlusIcon':
      rwIconComponent = <FaPlus className={`${furtherIconCss} text-white`} />
      break
    case 'SearchIcon':
      rwIconComponent = (
        <FaSearch className={`${furtherIconCss} text-primary`} />
      )
      break
    case 'WarningIcon': // triangle
      rwIconComponent = <IoWarningOutline {...rest} />
      break
    case '76Icon':
      rwIconComponent = (
        <CdnImg
          path={`_images/icons/76Icons-02.png`}
          className={`${furtherIconCss} p-0`}
          height="20px"
          alt="76 logo"
        />
      )
      break
    case '76IconDm':
      rwIconComponent = (
        <CdnImg
          path={`_images/76Icons-03-red.png`}
          className={`${furtherIconCss} p-0`}
          height="20px"
          alt="76 logo"
        />
      )
      break
    case 'UpsIcon':
      rwIconComponent = (
        <CdnImg
          path={`_images/icons/icon-UPS.gif`}
          className="p-0"
          height="20px"
          alt="ups logo"
        />
      )
      break
    case 'TForceIcon':
      rwIconComponent = (
        <CdnImg
          path={`_images/icons/icon_TForce.gif`}
          className="p-0"
          width="45px"
          alt="tforce logo"
        />
      )
      break
    case 'EportIcon':
      rwIconComponent = (
        <CdnImg
          path={`_images/ignition/eport.png`}
          className="p-0"
          width="45px"
          alt="eport logo"
        />
      )
      break

    case 'TlockIcon':
      rwIconComponent = (
        <CdnImg
          path={`_images/ignition/tlock.png`}
          className="p-0"
          width="45px"
          alt="tlock logo"
        />
      )
      break
    case 'DeadFishIcon':
      rwIconComponent = <GiFishbone {...rest} />
      break
    default:
      rwIconComponent = <span>Icon not found...</span>
      break
  }

  return <>{rwIconComponent}</>
}

export default RwIcon
