import { createContext, useReducer, useContext } from 'react'
import {
  GET_PRODUCTS,
  SEARCH_PRODUCTS,
  GET_PRODUCT_BY_ID,
  UPDATE_CART_PRODUCT,
  UPDATE_CART_PRODUCT_DETAILS,
  REMOVE_CART,
  DEL_COMPARE,
  CLEAR_CART,
  GET_CARTS,
  GET_CART,
  GET_CART_SUMMARY,
  GET_CART_TOTALS,
  REMOVE_CART_TOTALS_PLACEHOLDERS,
  GET_CART_SHIPPING_ADDRESS,
  GET_SHIPPING_QUOTES,
  GET_SHIPPING_QUOTE,
  GET_EXISTING_QUOTE,
  SET_CART_SHIPPING_ADDRESS_MODAL,
  GET_COUPON_CODE,
  GET_SAVED_CARTS_WITH_ITEMS,
  SET_CHECKOUT_STEPS_STATUS,
  CLEAR_CHECKOUT_STEPS_STATUS,
  CLEAR_CHECKOUT_STEPS_STATUS_EXCEPT_ONE,
  INITIALIZE_PRODUCT_STATE,
  AUTH_CREDIT_CARD,
  GET_CART_BILLING,
  SET_CC_PATH,
  SUBMIT_ORDER,
  SET_CREDIT_CARD_PAYMENT,
  UPDATE_PRODUCT_IN_CART,
  PURGE_OLD_CART_DATA,
  CLEAR_SHIPPING_QUOTES,
  CLEAR_SUBMIT_ORDER_RESPONSE,
  SET_CART_UPDATING,
  SET_CART_HAS_CARRIER_QUOTES,
  SET_CART_HAS_VALID_CARRIER,
  SET_PAGE,
  SET_PAGINATION,
  GET_CART_RECOMMENDED_PRODUCTS,
  SET_CART_FILTER,
  SET_PRODUCTS_SEO,
} from './constants'
import productReducer from './ProductReducer'
import { IContextProviderProps, ISeoMeta } from '../../types/interfaces'
import axios, { AxiosRequestConfig } from 'axios'
import authHeader from '../services/AuthHeader'
import { PUBLIC_API_URL, API_URL } from '../apiconfig'
import {
  ICart,
  ICartBilling,
  ICartShippingAddress,
  ICreditCardPayment,
  IPagination,
  IProductState,
  IShippingOptions,
  IShippingQuote,
  ISubmitOrderData,
  IUpdateCartProductProps,
  ICartFilter,
} from './types'
import { useNavigate } from 'react-router-dom'
import toast from '../../components/molecules/RwToast'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../queries/user/keyFactory'
import { useCheckCartStock } from '../../queries/cart'
import { SHOP_LIST_PATH } from '../../constants/constants'
import {
  updatedProductsAfterQuantityChange,
  updatedSingleProductAfterQuantityChange,
  PROJECT_ID,
  buildFilterFromLocation,
} from '../../helpers'
import { productKeys } from '../../queries/products/keyFactory'
import {
  IProductFilter,
  IProductFilterParams,
} from '../../queries/products/types'
import { PAYMENT_METHOD_CREDIT_CARD } from '../../constants/billing'
import { isProd } from '../../helpers/EnvDetect'
import { ICustomerData } from '../../queries/user/types'
import { useSiteContext } from '../site/SiteProvider'
import { cartSortOptions } from '../../constants/constants'
import history from '../history'

export const defaultCartFilter: ICartFilter = {
  sort: cartSortOptions.model,
}

export const initialState: IProductState = {
  carts: [],
  cartSummary: null,
  cartFilter: defaultCartFilter,
  cart: null,
  cartTotals: [],
  cartTotal: null,
  cartSubtotal: null,
  cartOptionFees: null,
  cartShippingAddress: null,
  cartShippingAddressIsValid: false,
  cartShippingAddressErrorMessage: '',
  cartShippingAddressModal: null,
  cartHasCarriersQuotes: false,
  shippingQuotes: [],
  shippingQuotesLoaded: false,
  shippingQuote: null,
  cartBilling: null,
  ccPath: '',
  products: [],
  product: null,
  couponCode: null,
  creditCardResponse: null,
  submitOrderResponse: null,
  favorites: [], // work in progress
  compare: [], // work in progress
  savedCartsWithItems: [],
  creditCardPayment: null,
  checkoutStepsStatus: null,
  oldCartDataPurged: null,
  pagination: null,
  productsSeo: null,
  cartUpdating: false,
  cartRecommendedProducts: [],
  setCartFilter: () => {},
  searchProducts: () => {},
  getProductByUrl: () => {},
  updateCartProduct: () => {},
  deleteCartProduct: () => {},
  removeCart: () => {},
  clearCart: () => {},
  getCarts: () => {},
  getCartSummary: () => {},
  getCart: () => {},
  getCarriers: () => {},
  getCartTotals: () => {},
  getCartCheckoutTotals: () => {},
  getCartShippingAddress: () => {},
  setCartShippingAddressModal: () => {},
  saveCartShippingAddress: () => {},
  setCartShippingAddress: () => {},
  getShippingQuote: () => {},
  getExistingQuote: () => {},
  updateCart: () => {},
  updatePickupInfo: () => {},
  updateCartName: () => {},
  getCouponCode: () => {},
  applyCouponCode: () => {},
  deleteCouponCode: () => {},
  setCart: () => {},
  newCart: () => {},
  getSavedCartsWithItems: () => {},
  authCreditCard: () => {},
  updateCartBilling: () => {},
  getCartBilling: () => {},
  setCcPath: () => {},
  submitOrder: () => {},
  clearCreditCardPayment: () => {},
  clearPaymentMethod: () => {},
  setCheckoutStepsStatus: () => {},
  clearCheckoutStepsStatus: () => {},
  initializeProductState: () => {},
  purgeOldCartData: () => {},
  clearShippingQuotes: () => {},
  clearSubmitOrderResponse: () => {},
  setCartUpdating: () => {},
  setCartHasCarriersQuotes: () => {},
  setPage: () => {},
  setPagination: () => {},
  setProductsSeo: () => {},
  getCartRecommendedProducts: () => {},
  updateCartRecommendedProduct: () => {},
}

export const ProductContext = createContext<IProductState>(initialState)

export const useProductContext = () => useContext(ProductContext)

export const ProductProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props

  const queryClient = useQueryClient()
  const [state, dispatch] = useReducer(productReducer, initialState)

  const {
    data: user,
    isLoading, // todo: consider leveraging this in the UI
    error,
    refetch: getAuthUser, // todo: confirm this works, and that invalidate isn't a better approach
  } = useQuery(userKeys.user)

  const customer = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  const { refetch: checkCartStock } = useCheckCartStock()

  const navigate = useNavigate()

  const setCartFilter = async (cartFilter: ICartFilter) => {
    dispatch({
      type: SET_CART_FILTER,
      payload: cartFilter,
    })
  }

  const searchProducts = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    const response = await axios.get(`${PUBLIC_API_URL}products`, headersValue)

    const dispatchObj = dispatch({
      type: SEARCH_PRODUCTS,
      payload: response.data,
    })
  }

  const getProductByUrl = async (products_url: string) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const projectIdParam = PROJECT_ID ? `?project_id=${PROJECT_ID}` : ''
    const response = await axios.get(
      `${PUBLIC_API_URL}products/get-by-url/${products_url}${projectIdParam}`,
      headersValue,
    )

    dispatch({
      type: GET_PRODUCT_BY_ID,
      payload: response.data,
    })
  }

  const setCartUpdating = (cartUpdating: Boolean) => {
    dispatch({
      type: SET_CART_UPDATING,
      payload: cartUpdating,
    })
  }

  const updateCartProduct = async (props: IUpdateCartProductProps) => {
    const { cartProduct, isProductDetails = false, isCart } = props

    const cartId = cartProduct ? cartProduct.customers_carts_id : 0

    //TODO handle page
    const pagination = state.pagination
    const page = pagination ? pagination.page : 1
    /*
     * update the context so useEffect will fire if quantity comes back
     * from api with original value
     */
    dispatch({
      type: isProductDetails
        ? UPDATE_CART_PRODUCT_DETAILS
        : UPDATE_CART_PRODUCT,
      payload: { data: cartProduct },
    })

    dispatch({
      type: UPDATE_PRODUCT_IN_CART,
      payload: { data: cartProduct },
    })

    // TODO figure out if this can be deleted
    // dispatch({
    //   type: SET_CART_UPDATING,
    //   payload: true,
    // })

    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    const response = await axios.post(
      `${API_URL}carts/update-customer-cart-product`,
      { cart_product: cartProduct, project_id: PROJECT_ID },
      headersValue,
    )

    let newCartDetected = false
    if (response.data.meta.new_cart_id) {
      console.log(`New cart id ${response.data.meta.new_cart_id} detected.`)
      toast.warning('Stale cart detected.  A new cart has been created', {
        autoClose: 5000,
      })
      newCartDetected = true
    }

    getCartSummary()
    if (isCart || newCartDetected) {
      getCartTotals()
      checkCartStock()

      if (response.data.message) {
        setTimeout(() => {
          getCart()
        }, 5000)
      } else {
        getCart()
      }
    }

    dispatch({
      type: isProductDetails
        ? UPDATE_CART_PRODUCT_DETAILS
        : UPDATE_CART_PRODUCT,
      payload: response.data,
    })

    dispatch({
      type: UPDATE_PRODUCT_IN_CART,
      payload: response.data,
    })

    // dispatch({
    //   type: SET_CART_UPDATING,
    //   payload: false,
    // })

    if (response.data) {
      const location = history.location

      if (location.pathname.toString().includes(SHOP_LIST_PATH)) {
        const filter = buildFilterFromLocation(location)
        const status = queryClient.getQueryData(userKeys.status()) as boolean

        const existingProductsList = queryClient.getQueryData(
          productKeys.products(filter, status, cartId, page),
        )

        const updatedProducts = await updatedProductsAfterQuantityChange(
          response,
          existingProductsList,
        )

        await queryClient.setQueryData(
          productKeys.products(filter, status, cartId, page),
          updatedProducts,
        )
      } else {
        // 'else' covers product details page; misses /cart, but no issue bc not a cache item
        const productKey = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1,
        )

        const existingProduct = queryClient.getQueryData(
          productKeys.product(productKey),
        ) as any

        const updatedProduct = updatedSingleProductAfterQuantityChange(
          response,
          existingProduct,
        )

        await queryClient.setQueryData(
          productKeys.product(productKey),
          updatedProduct,
        )
      }
    }
  }

  const deleteCartProduct = async (props: IUpdateCartProductProps) => {
    const { cartProduct, isProductDetails = false, isCart } = props

    const cartId = cartProduct ? cartProduct.customers_carts_id : 0

    //TODO handle page
    const page = 1

    /*
     * update the context so useEffect will fire if quantity comes back
     * from api with original value
     */
    if (!isCart) {
      dispatch({
        type: isProductDetails
          ? UPDATE_CART_PRODUCT_DETAILS
          : UPDATE_CART_PRODUCT,
        payload: { data: cartProduct },
      })

      dispatch({
        type: UPDATE_PRODUCT_IN_CART,
        payload: { data: cartProduct },
      })
    }
    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    const response = await axios.post(
      `${API_URL}carts/delete-customer-cart-product`,
      { id: cartProduct.id, project_id: PROJECT_ID },
      headersValue,
    )

    let newCartDetected = false
    if (response.data.meta.new_cart_id) {
      console.log(`New cart id ${response.data.meta.new_cart_id} detected.`)
      toast.warning('Stale cart detected.  A new cart has been created', {
        autoClose: 5000,
      })
      newCartDetected = true
    }

    getCartSummary()
    if (!newCartDetected) {
      toast.success('Product(s) removed from cart')
    }

    if (isCart || newCartDetected) {
      getCartTotals()
      getCart()
      checkCartStock()
      getCartRecommendedProducts(cartId)
    } else {
      dispatch({
        type: isProductDetails
          ? UPDATE_CART_PRODUCT_DETAILS
          : UPDATE_CART_PRODUCT,
        payload: response.data,
      })

      dispatch({
        type: UPDATE_PRODUCT_IN_CART,
        payload: response.data,
      })
    }

    if (response.data) {
      const location = history.location

      if (location.pathname.toString().includes(SHOP_LIST_PATH)) {
        const filter = buildFilterFromLocation(location)
        const status = queryClient.getQueryData(userKeys.status()) as boolean

        const existingProductsList = queryClient.getQueryData(
          productKeys.products(filter, status, cartId, page),
        )

        const updatedProducts = await updatedProductsAfterQuantityChange(
          response,
          existingProductsList,
        )

        await queryClient.setQueryData(
          productKeys.products(filter, status, cartId, page),
          updatedProducts,
        )
      } else {
        // 'else' covers product details page; misses /cart, but no issue bc not a cache item
        const productKey = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1,
        )

        const existingProduct = queryClient.getQueryData(
          productKeys.product(productKey),
        ) as any
        const updatedProduct = updatedSingleProductAfterQuantityChange(
          response,
          existingProduct,
        )

        await queryClient.setQueryData(
          productKeys.product(productKey),
          updatedProduct,
        )
      }
    }
  }

  const getCarts = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_URL}carts/select-saved-carts?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: GET_CARTS,
      payload: response.data,
    })
  }

  const getCartSummary = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_URL}carts/select-cart-summary?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: GET_CART_SUMMARY,
      payload: response.data,
    })

    dispatch({
      type: SET_CART_UPDATING,
      payload: false,
    })
  }

  const getCart = async (filter?: ICartFilter) => {
    // Construct query parameters
    const queryParams = new URLSearchParams({ project_id: PROJECT_ID })

    // Add filter parameters if they exist
    if (filter) {
      if (filter.sort) {
        queryParams.append('sort', filter.sort)
      }
    }

    // Construct the full URL with query parameters
    const url = `${API_URL}carts/select-cart?${queryParams.toString()}`

    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    try {
      const response = await axios.get(url, headersValue)

      if (response.data.new_cart_id) {
        console.log(`New cart id ${response.data.new_cart_id} detected.`)
        toast.warning('Stale cart detected. A new cart has been created', {
          autoClose: 5000,
        })
        getCarts()
        getCartSummary()
        getCartTotals()
      }

      dispatch({
        type: GET_CART,
        payload: response.data,
      })

      response.data.items.forEach((item: any) => {
        dispatch({
          type: UPDATE_PRODUCT_IN_CART,
          payload: item.delay,
        })
      })
    } catch (error) {
      console.error('Error fetching cart:', error)
      // Handle error appropriately, e.g., dispatch an error action or show a message to the user
    }
  }

  const getCarriers = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_URL}carts/select-carriers?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: GET_SHIPPING_QUOTES,
      payload: response.data.shipping_quotes,
    })
  }

  const getCartTotals = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_URL}carts/select-cart-totals`,
      {
        step: '01-cart',
        project_id: PROJECT_ID,
      },
      headersValue,
    )
    dispatch({
      type: GET_CART_TOTALS,
      payload: response.data,
    })
  }

  const getCartCheckoutTotals = async () => {
    dispatch({
      type: REMOVE_CART_TOTALS_PLACEHOLDERS,
      payload: null,
    })
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_URL}carts/select-cart-totals`,
      { project_id: PROJECT_ID },
      headersValue,
    )
    dispatch({
      type: GET_CART_TOTALS,
      payload: response.data,
    })
  }

  const getShippingQuote = async (options: IShippingOptions) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/shipping-quote`,
      {
        ...options,
        project_id: PROJECT_ID,
      },
      headersValue,
    )
    dispatch({
      type: GET_SHIPPING_QUOTE,
      payload: response.data,
    })
    if (options.selected) {
      getCartCheckoutTotals()
    }
  }

  const getExistingQuote = async (shipping_quotes_id: number) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.get(
      `${API_URL}carts/shipping-quote/${shipping_quotes_id}`,
      headersValue,
    )
    dispatch({
      type: GET_EXISTING_QUOTE,
      payload: response.data,
    })
  }

  const getCartShippingAddress = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_URL}carts/select-cart-shipping-address?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: GET_CART_SHIPPING_ADDRESS,
      payload: response.data,
    })
  }

  const setCartShippingAddressModal = async (
    shippingAddress: ICartShippingAddress | null,
  ) => {
    dispatch({
      type: SET_CART_SHIPPING_ADDRESS_MODAL,
      payload: shippingAddress,
    })
  }

  const setCartHasCarriersQuotes = (cartHasCarriersQuotes: Boolean) => {
    if (!cartHasCarriersQuotes) {
      dispatch({
        type: SET_CART_HAS_VALID_CARRIER,
        payload: false,
      })
    }

    dispatch({
      type: SET_CART_HAS_CARRIER_QUOTES,
      payload: cartHasCarriersQuotes,
    })
  }

  const saveCartShippingAddress = async (
    shippingAddress: ICartShippingAddress,
  ) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/save-shipping-address`,
      { shippingAddress: shippingAddress, project_id: PROJECT_ID },
      headersValue,
    )

    toast.success('Shipping Address Updated')

    dispatch({
      type: GET_CART_SHIPPING_ADDRESS,
      payload: response.data,
    })

    getCarriers()

    dispatch({
      type: CLEAR_SHIPPING_QUOTES,
      payload: null,
    })
  }

  const updateCart = async (cart: ICart, navigateTo?: string | null) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_URL}carts/update-cart`,
      { cart: cart, project_id: PROJECT_ID },
      headersValue,
    )

    if (navigateTo) {
      navigate(navigateTo)
    } else {
      getCart()
      getCartCheckoutTotals()
    }
  }

  const updatePickupInfo = async (cart: ICart) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    try {
      const response = await axios.post(
        `${API_URL}carts/update-pickup-info`,
        cart,
        headersValue,
      )
      if (response.data?.success) {
        // no dispatch, bc orders fetched again in component
        toast.success('Update successful.')
      } else {
        toast.error('Update unsuccessful.')
      }
    } catch (error) {
      toast.error('Update unsuccessful.')
    }
  }

  const updateCartName = async (cart_name: string) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_URL}carts/update-cart`,
      { cart_name: cart_name, project_id: PROJECT_ID },
      headersValue,
    )

    toast.success('Cart Name Updated')

    getCart()
    getCartTotals()
  }

  const setCartShippingAddress = async (
    shipping_address_id: number,
    is_customer_address: boolean,
  ) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_URL}carts/set-shipping-address`,
      { shipping_address_id, is_customer_address, project_id: PROJECT_ID },
      headersValue,
    )

    dispatch({
      type: GET_CART_SHIPPING_ADDRESS,
      payload: response.data,
    })
  }

  const getCouponCode = async () => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.get(
      `${API_URL}carts/get-coupon-code?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: GET_COUPON_CODE,
      payload: response.data,
    })
  }

  const applyCouponCode = async (coupon_code: string) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/apply-coupon-code`,
      { coupon_code: coupon_code, project_id: PROJECT_ID },
      headersValue,
    )

    if (response) {
      if (response?.status !== 200 || response?.data.success === false) {
        toast.error(`${response.data.error || 'An error occurred.'}`)
      } else if (response?.data.coupon_code) {
        toast.success(
          `${
            response?.data?.coupon_code
              ? response.data.coupon_code + ' Coupon Code Applied.'
              : 'Coupon Code Applied.'
          }`,
        )
        getCartCheckoutTotals()

        dispatch({
          type: GET_COUPON_CODE,
          payload: response.data,
        })
      }
    }
  }

  const deleteCouponCode = async () => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/delete-coupon-code`,
      { project_id: PROJECT_ID },
      headersValue,
    )

    toast.success('Coupon Code Deleted')

    getCartCheckoutTotals()

    dispatch({
      type: GET_COUPON_CODE,
      payload: null,
    })
  }

  const setCart = async (id: number, navigateTo?: string | null) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/set-cart`,
      { id: id, project_id: PROJECT_ID },
      headersValue,
    )

    await Promise.all([getSavedCartsWithItems(), getCart()])
    getAuthUser()
    getCarts()
    getCartSummary()
    getCartTotals()

    if (navigateTo) {
      navigate(navigateTo)
    } else {
      toast.success('Saved cart has been loaded.')
    }
  }

  const newCart = async (showFeedback: boolean = false) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/new-cart`,
      { project_id: PROJECT_ID },
      headersValue,
    )

    if (showFeedback) {
      toast.success(
        'Current cart has been saved and new cart has been created.',
      )
    }

    // getCart()
    // getCartSummary() // Both being called more globally in HeaderNavBar useEffect when customer.customers_carts_id is updated
    getAuthUser()
    getCartTotals()
    getCarts()
    getSavedCartsWithItems()
  }

  const authCreditCard = async (data: ICreditCardPayment) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/auth-credit-card`,
      data,
      headersValue,
    )

    dispatch({
      type: AUTH_CREDIT_CARD,
      payload: response.data,
    })
  }

  const updateCartBilling = async (
    cartBilling: ICartBilling,
    navigateTo?: string | null,
  ) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/save-billing`,
      {
        ...cartBilling,
        cc_number: null,
        cc_expire_month: null,
        cc_expire_year: null,
        cc_cvv: null,
        cc_deposit_amount: null,
        project_id: PROJECT_ID,
      },
      headersValue,
    )

    if (navigateTo) {
      navigate(navigateTo)
    } else {
      getCartCheckoutTotals()
    }

    dispatch({
      type: SET_CREDIT_CARD_PAYMENT,
      payload: {
        cc_number: cartBilling.cc_number,
        cc_expire_month: cartBilling.cc_expire_month,
        cc_expire_year: cartBilling.cc_expire_year,
        cc_cvv: cartBilling.cc_cvv,
        cc_deposit_amount: cartBilling.cc_deposit_amount,
      },
    })

    dispatch({
      type: GET_CART_BILLING,
      payload: response.data,
    })
  }

  const clearCreditCardPayment = async () => {
    dispatch({
      type: SET_CREDIT_CARD_PAYMENT,
      payload: null,
    })
  }

  const clearPaymentMethod = async () => {
    if (!state.cartBilling) {
      //if cartBilling is null then there is nothing to clear
      return
    }

    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const response = await axios.post(
      `${API_URL}carts/save-billing`,
      {
        id: state.cartBilling.id,
        payment_method: null,
        project_id: PROJECT_ID,
      },
      headersValue,
    )

    dispatch({
      type: SET_CREDIT_CARD_PAYMENT,
      payload: null,
    })

    dispatch({
      type: GET_CART_BILLING,
      payload: response.data,
    })
  }

  const getCartBilling = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_URL}carts/select-cart-billing?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: GET_CART_BILLING,
      payload: response.data,
    })
  }

  const setCcPath = (path: string) => {
    dispatch({
      type: SET_CC_PATH,
      payload: path,
    })
  }

  const submitOrder = async (
    data: ISubmitOrderData,
    navigateTo?: string | null,
  ) => {
    const headersValue: AxiosRequestConfig = {
      headers: authHeader(),
    }
    const { cartSubtotal, cart } = state

    try {
      const response = await axios.post(
        `${API_URL}carts/submit-order`,
        { ...data, ...state.creditCardPayment },
        headersValue,
      )

      if (response.data.success) {
        if (isProd()) {
          try {
            window.iAdvizeInterface = window.iAdvizeInterface || []
            window.iAdvizeInterface.push(function (iAdvize: any) {
              iAdvize.recordTransaction({
                amount: cartSubtotal,
                id: cart.id.toString(),
              })
            })

            window.Ds && window.Ds.conversion('ghll4li8fy6fzjnc', data.subtotal)
          } catch (e) {}
        } else {
          console.log('DailyStory Conversion not fired bc !isProd.')
        }

        newCart()
        dispatch({
          type: SET_CREDIT_CARD_PAYMENT,
          payload: null,
        })
        dispatch({
          type: SUBMIT_ORDER,
          payload: response.data,
        })
        if (navigateTo) {
          navigate(navigateTo)
        }
        toast.success(
          `${
            response?.data?.message
              ? response?.data?.message
              : 'Order submitted.'
          }`,
        )
      } else {
        const errorMsg = response.data.errors
          ? response.data.errors.join('\n')
          : 'An error occurred'

        if (response.data?.card_declined) {
          await clearCheckoutStepsStatus()
          await setCheckoutStepsStatus('checkout')
          await setCheckoutStepsStatus('shipping')
          toast.error('Card Transaction Declined')
          dispatch({
            type: SUBMIT_ORDER,
            payload: response.data,
          })
        } else {
          toast.error('An error occurred')
          dispatch({
            type: SUBMIT_ORDER,
            payload: {
              success: false,
              data: null,
              errors: [errorMsg],
            },
          })
        }
      }
    } catch (error) {
      const errorMsg =
        'There was an error processing your order. Please contact customer service at 573-447-1776'
      toast.error(errorMsg)
      dispatch({
        type: SUBMIT_ORDER,
        payload: {
          success: false,
          data: null,
          errors: [errorMsg],
        },
      })
    }
  }

  const removeCart = (id: number) => {
    // Axios stuff here
    // product removed in reducer. this will change when apis in place
    dispatch({
      type: REMOVE_CART,
      payload: id,
    })
  }

  const delCompare = (id: number) => {
    // Axios stuff here
    // product removed in reducer. this will change when apis in place
    dispatch({
      type: DEL_COMPARE,
      payload: id,
    })
  }

  const clearCart = () => {
    // Axios stuff here
    // products removed in reducer. this will change when apis in place
    dispatch({
      type: CLEAR_CART,
      payload: null,
    })
  }

  const getSavedCartsWithItems = async (
    filter?: ICartFilter,
    limit?: number,
  ) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_URL}carts/select-saved-carts-with-items`,
      {
        limit: limit,
        filter: filter,
        project_id: PROJECT_ID,
      },
      headersValue,
    )
    dispatch({
      type: GET_SAVED_CARTS_WITH_ITEMS,
      payload: response.data,
    })
  }

  const setCheckoutStepsStatus = async (setStepToTrue: string) => {
    dispatch({
      type: SET_CHECKOUT_STEPS_STATUS,
      payload: setStepToTrue,
    })
  }
  const clearCheckoutStepsStatus = async (optionalStepToKeep?: string) => {
    if (optionalStepToKeep) {
      dispatch({
        type: CLEAR_CHECKOUT_STEPS_STATUS_EXCEPT_ONE,
        payload: optionalStepToKeep,
      })
    } else {
      dispatch({
        type: CLEAR_CHECKOUT_STEPS_STATUS,
        payload: null,
      })
    }
  }

  const clearShippingQuotes = async () => {
    dispatch({
      type: CLEAR_SHIPPING_QUOTES,
      payload: null,
    })
  }

  const clearSubmitOrderResponse = async () => {
    dispatch({
      type: CLEAR_SUBMIT_ORDER_RESPONSE,
      payload: null,
    })
  }

  const initializeProductState = async () => {
    dispatch({
      type: INITIALIZE_PRODUCT_STATE,
      payload: null,
    })
  }

  const purgeOldCartData = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    try {
      const response = await axios.get(
        `${API_URL}carts/purge-old-cart-data?project_id=${PROJECT_ID}`,
        headersValue,
      )
      dispatch({
        type: PURGE_OLD_CART_DATA,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: PURGE_OLD_CART_DATA,
        payload: {
          success: false,
          date_purged: new Date().toISOString(),
        },
      })
    }
  }

  const setPage = async (page: number) => {
    dispatch({
      type: SET_PAGE,
      payload: page,
    })
  }

  const setPagination = async (pagination: IPagination) => {
    dispatch({
      type: SET_PAGINATION,
      payload: pagination,
    })
  }

  const getCartRecommendedProducts = async (cartId: number) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    try {
      const response = await axios.post(
        `${API_URL}carts/get-recommended-products`,
        {
          customer,
          cartId,
          projectId: PROJECT_ID,
        },
        headersValue,
      )

      dispatch({
        type: GET_CART_RECOMMENDED_PRODUCTS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: GET_CART_RECOMMENDED_PRODUCTS,
        payload: [],
      })
    }
  }

  const updateCartRecommendedProduct = async (
    updateProps: IUpdateCartProductProps,
  ) => {
    await updateCartProduct({ ...updateProps })
    toast.success('Product added to cart')

    const cartId = updateProps.cartProduct.customers_carts_id
    getCartRecommendedProducts(cartId)

    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    axios.post(
      `${API_URL}carts/recommended-click`,
      { ...updateProps },
      headersValue,
    )
  }

  const setProductsSeo = (productsSeo: ISeoMeta) => {
    dispatch({
      type: SET_PRODUCTS_SEO,
      payload: productsSeo,
    })
  }

  const value = {
    products: state.products,
    product: state.product,
    carts: state.carts,
    cartSummary: state.cartSummary,
    cart: state.cart,
    cartTotals: state.cartTotals,
    cartTotal: state.cartTotal,
    cartSubtotal: state.cartSubtotal,
    cartOptionFees: state.cartOptionFees,
    cartShippingAddress: state.cartShippingAddress,
    cartShippingAddressIsValid: state.cartShippingAddressIsValid,
    cartShippingAddressErrorMessage: state.cartShippingAddressErrorMessage,
    cartShippingAddressModal: state.cartShippingAddressModal,
    cartBilling: state.cartBilling,
    ccPath: state.ccPath,
    shippingQuotes: state.shippingQuotes,
    shippingQuotesLoaded: state.shippingQuotesLoaded,
    shippingQuote: state.shippingQuote,
    couponCode: state.couponCode,
    creditCardResponse: state.creditCardResponse,
    submitOrderResponse: state.submitOrderResponse,
    favorites: state.favorites,
    compare: state.compare,
    savedCartsWithItems: state.savedCartsWithItems,
    creditCardPayment: state.creditCardPayment,
    checkoutStepsStatus: state.checkoutStepsStatus,
    oldCartDataPurged: state.oldCartDataPurged,
    cartUpdating: state.cartUpdating,
    cartHasCarriersQuotes: state.cartHasCarriersQuotes,
    pagination: state.pagination,
    productsSeo: state.productsSeo,
    cartRecommendedProducts: state.cartRecommendedProducts,
    cartFilter: state.cartFilter,
    searchProducts,
    getProductByUrl,
    updateCartProduct,
    deleteCartProduct,
    setCartFilter,
    getCarts,
    getCartSummary,
    getCart,
    getCarriers,
    getCartTotals,
    getCartCheckoutTotals,
    getCartShippingAddress,
    setCartShippingAddressModal,
    saveCartShippingAddress,
    setCartShippingAddress,
    getShippingQuote,
    getExistingQuote,
    updateCart,
    updatePickupInfo,
    updateCartName,
    getCouponCode,
    applyCouponCode,
    deleteCouponCode,
    setCart,
    newCart,
    authCreditCard,
    updateCartBilling,
    getCartBilling,
    submitOrder,
    setCcPath,
    clearCreditCardPayment,
    clearPaymentMethod,
    removeCart,
    clearCart,
    getSavedCartsWithItems,
    setCheckoutStepsStatus,
    clearCheckoutStepsStatus,
    initializeProductState,
    purgeOldCartData,
    clearShippingQuotes,
    clearSubmitOrderResponse,
    setCartUpdating,
    setCartHasCarriersQuotes,
    setPage,
    setPagination,
    setProductsSeo,
    getCartRecommendedProducts,
    updateCartRecommendedProduct,
  }

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  )
}
