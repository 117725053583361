import {
  Routes as ReactRoutes,
  Route,
  Outlet,
  useNavigate,
} from 'react-router-dom'
import loadable from '../components/organisms/Common/loader/loadable'
import Page404 from '../components/pages/page404'
import Page503 from '../components/pages/page503'
import PrivateRoute from './PrivateRoute'
import { IMarketingPage, IRedirect } from '../queries/site/types'
import {
  useActiveRedirectsData,
  useMarketingPages,
  useMarketingPageStageRoutes,
  useMarketingPosts,
  useMarketingPostStageRoutes,
} from '../queries/site'
import { useEffect } from 'react'
import history from '../context/history'
import { isProline } from '../helpers'

export const HIDE_GLOBAL_HEADER_PATHS = [
  '/login',
  '/login-as',
  '/login/reset-password',
  '/login/reset-password-method',
  '/login/reset-password/verification',
  '/login/reset-password/submit-new-password',
  '/register',
]

const ShopList = loadable(() => import('../components/pages/shop-list'))
const ProductDetails = loadable(
  () => import('../components/pages/product/index'),
)
const Login = loadable(() => import('../components/pages/login/login'))
const OtpLogin = loadable(() => import('../components/pages/login/otpLogin'))
const ResetPassword = loadable(
  () => import('../components/pages/login/reset-password'),
)
const ResetPasswordMethod = loadable(
  () => import('../components/pages/login/reset-password-method'),
)
const Verification = loadable(
  () => import('../components/pages/login/verification'),
)
const SubmitNewPassword = loadable(
  () => import('../components/pages/login/submit-new-password'),
)
const Register = loadable(() => import('../components/pages/register'))
const ProUse = loadable(() => import('../components/pages/pro-use'))

const Account = loadable(() => import('../components/pages/account'))
const Forms = loadable(() => import('../components/pages/forms'))
const Cart = loadable(() => import('../components/pages/cart'))
const Shipping = loadable(() => import('../components/pages/checkout/shipping'))
const Payment = loadable(() => import('../components/pages/checkout/payment'))
const Review = loadable(() => import('../components/pages/checkout/review'))
const Complete = loadable(() => import('../components/pages/checkout/complete'))
const FireworksShowBuilder = loadable(
  () => import('../components/pages/glow/fireworks-show-builder'),
)
const ViewMyFireworksShow = loadable(
  () => import('../components/pages/glow/view-my-fireworks-show'),
)

const ScrollToTop = loadable(
  () => import('../components/organisms/Common/ScrollToTop'),
)
const Landing = loadable(() => import('../components/pages'))
const MarketingPage = loadable(
  () => import('../components/pages/marketing-page'),
)
const MarketingPost = loadable(
  () => import('../components/pages/marketing-post'),
)
const MarketingPosts = loadable(
  () => import('../components/pages/marketing-posts'),
)
const About = loadable(() => import('../components/pages/marketing/about'))
const Contact = loadable(() => import('../components/pages/marketing/contact'))
const Resources = loadable(
  () => import('../components/pages/marketing/resources'),
)

const ShippingInfo = loadable(
  () => import('../components/pages/marketing/shipping'),
)
const MsdsSds = loadable(() => import('../components/pages/marketing/msds-sds'))
const News = loadable(() => import('../components/pages/marketing/news'))
const Italia = loadable(() => import('../components/pages/marketing/italia'))
const DisplayShowcase = loadable(
  () => import('../components/pages/marketing/display-showcase'),
)
const Catalog = loadable(() => import('../components/pages/marketing/catalog'))
const Faq = loadable(() => import('../components/pages/marketing/faq'))
const IgnitionTypes = loadable(
  () => import('../components/pages/marketing/ignition-types'),
)
const Pyrolamas = loadable(
  () => import('../components/pages/marketing/pyrolamas'),
)
const Discounts = loadable(
  () => import('../components/pages/marketing/discounts'),
)

const RedirectPage: React.FC<{ redirectTo: IRedirect }> = ({ redirectTo }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (redirectTo.source_url === window.location.pathname) {
      if (redirectTo.external) {
        window.location.href = redirectTo.target_url
      } else {
        if (redirectTo.is_filter) {
          history.push(redirectTo.target_url)
          navigate(redirectTo.target_url)
        } else {
          navigate(redirectTo.target_url)
        }
      }
    }
  }, [redirectTo, navigate])

  return <Outlet />
}

const Routes = () => {
  const { data: redirects, isLoading: redirectsAreLoading } =
    useActiveRedirectsData()

  const { data: marketingPages, isLoading: marketingPagesAreLoading } =
    useMarketingPages()

  const { data: marketingPosts, isLoading: marketingPostsAreLoading } =
    useMarketingPosts()

  const {
    data: marketingPageStageRoutes,
    isLoading: marketingPageStageRoutesAreLoading,
  } = useMarketingPageStageRoutes()

  const {
    data: marketingPostStageRoutes,
    isLoading: marketingPostStageRoutesAreLoading,
  } = useMarketingPostStageRoutes()

  return (
    <div className="body_size">
      <ScrollToTop />
      <ReactRoutes>
        {redirects?.map((redirect: IRedirect, index: number) => (
          <Route
            key={index}
            path={redirect.source_url}
            element={<RedirectPage redirectTo={redirect} />}
          />
        ))}

        {marketingPages?.map((marketingPage: IMarketingPage) => (
          <Route
            key={marketingPage?.id}
            path={marketingPage?.path}
            element={<MarketingPage />}
          />
        ))}

        {marketingPosts?.map((marketingPost: IMarketingPage) => (
          <Route
            key={marketingPost?.id}
            path={marketingPost?.path}
            element={<MarketingPost />}
          />
        ))}

        {marketingPageStageRoutes?.map((marketingPage: IMarketingPage) => (
          <Route
            key={marketingPage?.id}
            path={`/marketing-stage${marketingPage?.path}`}
            element={<MarketingPage />}
          />
        ))}

        {marketingPostStageRoutes?.map((marketingPage: IMarketingPage) => (
          <Route
            key={marketingPage?.id}
            path={`/marketing-stage${marketingPage?.path}`}
            element={<MarketingPost />}
          />
        ))}

        <Route path="/" element={<Landing />} />

        {isProline() ? (
          <Route path="/news" element={<MarketingPosts />} />
        ) : (
          <Route path="/news" element={<MarketingPosts />} />
        )}

        <Route path="/firework-news" element={<MarketingPosts />} />
        <Route path="/shipping" element={<ShippingInfo />} />

        <Route path="/sl/*" element={<ShopList />} />
        <Route path="/c/*" element={<ShopList />} />
        <Route path="/b/*" element={<ShopList />} />
        <Route path="/l/*" element={<ShopList />} />
        <Route path="/category/:category_url" element={<ShopList />} />
        <Route path="/brand/:brand_url" element={<ShopList />} />
        <Route path="/list/:list_url" element={<ShopList />} />
        <Route path="/shoplist" element={<ShopList />} />
        <Route path="/pro-use" element={<ProUse />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product-msds-sds" element={<MsdsSds />} />
        <Route path="/product/:products_url" element={<ProductDetails />} />

        <Route path="/login" element={<Login />} />
        <Route path="/login-as" element={<OtpLogin />} />
        <Route path="/login/reset-password" element={<ResetPassword />} />
        <Route
          path="/login/reset-password-method"
          element={<ResetPasswordMethod />}
        />
        <Route
          path="/login/reset-password/verification"
          element={<Verification />}
        />
        <Route
          path="/login/reset-password/submit-new-password"
          element={<SubmitNewPassword />}
        />

        <Route path="/register" element={<Register />} />
        <Route path="/pages/*" element={<Forms />} />

        <Route
          path="/fireworks-show-builder/*"
          element={<FireworksShowBuilder />}
        />
        <Route
          path="/view-my-fireworks-show/*"
          element={<ViewMyFireworksShow />}
        />

        <Route element={<PrivateRoute />}>
          <Route path="/account/*" element={<Account />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Shipping />} />
          <Route path="/checkout/shipping" element={<Shipping />} />
          <Route path="/checkout/payment" element={<Payment />} />
          <Route path="/checkout/review" element={<Review />} />
          <Route path="/checkout/complete" element={<Complete />} />
        </Route>

        <Route path="/down-for-maintenance" element={<Page503 />} />

        <Route
          path="*"
          element={
            redirectsAreLoading ||
            marketingPagesAreLoading ||
            marketingPostsAreLoading ? (
              <div></div>
            ) : (
              <Page404 />
            )
          }
        />

        <Route path="/about" element={<About />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/italia" element={<Italia />} />
        <Route path="/display-showcase" element={<DisplayShowcase />} />
        <Route path="/pyrolamas" element={<Pyrolamas />} />
        <Route path="/ignition-types" element={<IgnitionTypes />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/proline-catalog" element={<Catalog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/discounts" element={<Discounts />} />
      </ReactRoutes>
    </div>
  )
}

export default Routes
