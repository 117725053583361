import React, { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Button,
  Navbar,
  Nav,
  Row,
  NavDropdown,
  DropdownButton,
  Spinner,
} from 'react-bootstrap'
import CdnImg from '../Common/CdnImg'
import HeaderSearchBar from '../HeaderSearchBar'
import RwIconTextLink from '../../molecules/RwIconTextLink'
import RwIcon from '../../molecules/RwIcon'
import { default as CartLink } from '../Common/IconLink'
import { useProductContext } from '../../../context/products/ProductProvider'
import { generalBsBreakpoint } from '../../../constants/constants'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useQueryClient } from '@tanstack/react-query'
import { ICustomerData, IUserData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import { useUserContext } from '../../../context/user/UserProvider'
import { isImpersonate } from '../../../helpers'
import FlashSaleBanner from '../Common/FlashSaleBanner'
import AccountDropdownItems from '../Account/AccountDropdownItems'
import ImpersonateMessage from './ImpersonateMessage'
import PriceListDropdown from './PriceListDropdown'
import WholesaleOffcanvas from './WholesaleOffcanvas'
import ProLineOffcanvas from './ProLineOffcanvas'
import { isProline } from '../../../helpers'

const headerNavBarBreakpoint = generalBsBreakpoint

interface IMobileMainHeaderDiv {
  brandLogoPath: string
  handleLogout: () => void
}

const MobileMainHeaderDiv: React.FC<IMobileMainHeaderDiv> = ({
  brandLogoPath,
  handleLogout,
}) => {
  const [show, setShow] = React.useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const status = queryClient.getQueryData(userKeys.status()) as boolean
  const customer = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  const { isDarkMode } = useUserContext()
  const { cartSummary, cartUpdating } = useProductContext()
  const {
    hamburgerMenuItems,
    appSettings,
    setMobileMainHeaderDivHeight,
    showFlashSaleBanner,
    flashSaleBannerMobile,
  } = useSiteContext()

  const clientHeightRef = ref.current ? ref.current.clientHeight : 0

  useEffect(() => {
    const clientHeight = ref.current ? ref.current.clientHeight : 0
    const mainHeaderDivHeight = clientHeight | 0
    setMobileMainHeaderDivHeight(mainHeaderDivHeight)
  }, [clientHeightRef, showFlashSaleBanner, flashSaleBannerMobile])

  const location = useLocation()

  return (
    <div
      ref={ref}
      id="main-header-div-mobile"
      className={`fixed-top ${
        isImpersonate() ? 'bg-warning' : isDarkMode ? 'dm-bg' : 'bg-white'
      }`}
    >
      <FlashSaleBanner />
      <div className="ps-3 pe-4 mt-1">
        <Navbar
          bg={isImpersonate() ? 'warning' : ''}
          expand={headerNavBarBreakpoint}
          className="py-0 my-0 ms-auto"
        >
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
            className={`px-2 me-2 shadow-none`}
            onClick={handleShow}
          />
          {/* this Logo displays on smaller than Breakpoint */}
          <Navbar.Brand
            as={Link}
            to="/"
            className={`py-0 my-0 d-${headerNavBarBreakpoint}-none me-auto`}
          >
            <CdnImg path={brandLogoPath} alt="logo" width="100" />
          </Navbar.Brand>
          {isProline() ? (
            <ProLineOffcanvas show={show} handleClose={handleClose} />
          ) : (
            <WholesaleOffcanvas show={show} handleClose={handleClose} />
          )}
          <Row className="pt-2">
            {status ? (
              <>
                <div
                  className={`col d-${headerNavBarBreakpoint}-none ms-auto pe-2 pt-0 m-0 box-shadow-none`}
                >
                  <DropdownButton
                    title={
                      <span className="text-body">
                        {user?.first} <RwIcon icon="UserIcon" />
                      </span>
                    }
                    id="user_nav_dropdown"
                    align="end"
                    className="no-focus-dropdown-button"
                    variant={isDarkMode ? 'dm-bg' : 'white'}
                  >
                    <>
                      <h5 className="p-1">My Account</h5>
                      <AccountDropdownItems />
                      <RwIconTextLink
                        containerClassProps=""
                        textProps="Sign Out"
                        onClickProps={handleLogout}
                        icon={<RwIcon icon="SignOutIcon" />}
                      />
                    </>
                  </DropdownButton>
                </div>

                <Nav.Item className="col pe-4 pt-1 ps-0 ms-auto">
                  {cartUpdating ? (
                    <>
                      <Spinner size="sm" animation="border" />
                    </>
                  ) : (
                    <CartLink
                      linkTo="/cart"
                      fontAwesomeIcon="fa-shopping-cart"
                      badgeBsTextColor="white"
                      badgeBsBgColor="accent"
                      badgeDisplayNumber={cartSummary?.cart_item_count}
                      iconBsColor={isDarkMode ? 'light' : 'primary'}
                    />
                  )}
                </Nav.Item>
              </>
            ) : (
              <Nav className="col justify-content-end ">
                {location.pathname !== '/login' && (
                  <Link to="/login">
                    <Button
                      variant={isDarkMode ? 'dm-proline-primary' : 'primary'}
                      style={{ zIndex: 9999999999 }}
                      className={`${isDarkMode ? 'border-white' : ''}`}
                    >
                      <RwIcon icon="UserIcon" /> Login
                    </Button>
                  </Link>
                )}
              </Nav>
            )}
          </Row>
        </Navbar>
      </div>
      <Row>
        {status && (
          <>
            {/* spacer div required for dropdown contents' position */}
            <div className="col-6">
              <ImpersonateMessage />
            </div>
            <div className="col-6 text-end">
              <div className="d-flex align-items-center justify-content-end">
                {!!customer?.special_order_enabled && (
                  <div className="pe-4" style={{ whiteSpace: 'nowrap' }}>
                    Special Order Pricing
                  </div>
                )}
                <div className="ps-2 pe-3">
                  <NavDropdown
                    title={
                      <span
                        className={`${
                          isDarkMode ? '' : 'text-dark'
                        } text-capitalize`}
                      >
                        {customer?.price_levels_name} Price List
                      </span>
                    }
                    id="user_nav_dropdown"
                  >
                    <PriceListDropdown customer={customer} />
                  </NavDropdown>
                </div>
              </div>
            </div>
          </>
        )}
      </Row>

      <div className="border-top mt-1 ps-1 py-2">
        <HeaderSearchBar />
      </div>
    </div>
  )
}

export default MobileMainHeaderDiv
