import React from 'react'
import Button from 'react-bootstrap/Button'
import RwIcon from '../../molecules/RwIcon'
import { useUserContext } from '../../../context/user/UserProvider'
import WholesaleOffcanvas from './WholesaleOffcanvas'
import ProLineOffcanvas from './ProLineOffcanvas'
import { isProline } from '../../../helpers'

const SideNavMenu: React.FC = () => {
  const [showSide, setShowSide] = React.useState<boolean>(false)
  const { isDarkMode } = useUserContext()
  const handleClose = () => setShowSide(false)
  const handleShow = () => setShowSide(true)

  return (
    <>
      <div>
        <div className="hamburger-menu-button">
          <Button
            variant={isDarkMode ? '' : 'white'}
            onClick={handleShow}
            className="button_text_bottom no-focus-dropdown-button"
          >
            <div
              className={`d-flex align-items-center pb-1 ${
                isDarkMode ? '' : 'text-primary'
              }`}
            >
              <div className="pe-" style={{ lineHeight: '10px' }}>
                <RwIcon icon="HamburgerIcon" />
              </div>
              <div className="ms-1">Shop All</div>
            </div>
          </Button>
        </div>
      </div>
      {isProline() ? (
        <ProLineOffcanvas show={showSide} handleClose={handleClose} />
      ) : (
        <WholesaleOffcanvas show={showSide} handleClose={handleClose} />
      )}
    </>
  )
}

export default SideNavMenu
