import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import authHeader from '../../context/services/AuthHeader'
import {
  AUTH_URL,
  API_CUSTOMERS_URL,
  PUBLIC_API_URL,
  API_URL,
} from '../../context/apiconfig'
import toast from '../../components/molecules/RwToast'
import {
  ILoginData,
  IQueryClient,
  IRegisterUser,
  ISubmitRequestForm,
  IUpdatedProfileInfo,
} from './types'
import { userKeys } from './keyFactory'
import { useUserContext } from '../../context/user/UserProvider'
import { documentKeys } from '../documents/keyFactory'
import { useProductContext } from '../../context/products/ProductProvider'
import { filterKeys } from '../filters/keyFactory'
import { isDev } from '../../helpers/EnvDetect'
import { orderStatusTypePreviousIds } from '../../constants/db'
import { PROJECT_ID } from '../../helpers'
//! Note: based on how this had been set up, I - CR - shoehorned the customer and status data which was in React Context into this user-related query
//! I think this created special-case headaches. I think it's simpler if a single query returns the appropriate, pre-processed data for a single queryKey.

export function useGetAuthUser() {
  const { initializeUserState } = useUserContext()
  const { initializeProductState } = useProductContext()

  const queryClient = useQueryClient()
  const isDevEnv = isDev()

  return useQuery({
    queryKey: userKeys.user,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${AUTH_URL}me?project_id=${PROJECT_ID}`,
        headersValue,
      )

      return response.data
    },
    onError: (error) => {
      //NOTE: By clearing user and related data, it's like a logout.  ...so loss of signal will cause this quasi-logout.  So, keep stale time high.

      // axios 401 error (which this api returns if error) and no connection errors here

      // todo: test this... I think we want to clear user and customer if no response.

      // clean client
      if (!isDevEnv) {
        // I think we want to clear user and customer if no response / 401 in isDev.  but dev hot-updates also reload and throws error, so the condition is necessary (due to placement of CacheInitialization).

        queryClient.setQueryData(userKeys.user, null)
        queryClient.setQueryData(userKeys.customer(), null)
        queryClient.setQueryData(userKeys.status(), false)

        localStorage.removeItem('user')
        document.cookie = `document_token= ; path=/; max-age=-1;`

        queryClient.removeQueries(documentKeys.accountDocuments)

        queryClient.setQueryData(userKeys.userProfile(), null)
        initializeUserState()
        initializeProductState()
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(userKeys.user, data.user)
      queryClient.setQueryData(userKeys.customer(), data.customer)
      queryClient.setQueryData(userKeys.status(), true)
      if (data.access_token) {
        localStorage.setItem('user', JSON.stringify(data))
      }
      document.cookie = `document_token=${data.document_token}; path=/`
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours // keep high. see Note above.
  })
}

export function useCustomerWatcher() {
  return useQuery({
    queryKey: userKeys.customer(),
    queryFn: async () => {
      return false // initialize, so mutation is possible
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours
  })
}
export function useStatusWatcher() {
  return useQuery({
    queryKey: userKeys.status(),
    queryFn: async () => {
      return null // initialize, so mutation is possible
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours
  })
}

export function useGetUserProfile() {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: userKeys.userProfile(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }

      const response = await axios.get(
        `${API_CUSTOMERS_URL}select-profile`,
        headersValue,
      )
      return response.data //! NEED TO CHECK, is this getting through? need the .data?
      //! ALSO what about this:
      // if (response?.status === 200) {
      //   return true
      // } else {
      //   return false
      // } // perhaps a hack. but need to use bc api response currently lacks "success: true/false". and using success response to pass on so a promise can be awaited.
    },
    onError: (error) => {
      // axios 401 error (which this api returns if error) and no connection errors here

      queryClient.setQueryData(userKeys.userProfile(), null)
    },
    onSuccess: (data) => {
      queryClient.setQueryData(userKeys.userProfile(), data)
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours
  })
}

export const updateUserProfile = async (variables: IUpdatedProfileInfo) => {
  const { updatedProfileInfo, queryClient } = variables

  const headersValue: AxiosRequestConfig = { headers: authHeader() }
  const response = await axios.post(
    `${API_CUSTOMERS_URL}update-profile`,
    updatedProfileInfo,
    headersValue,
  )

  if (!response.data) {
    // toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    toast.error(`${'An error occurred.'}`)
  } else {
    toast.success(`${response?.data?.message || 'Updated.'}`)

    queryClient.setQueryData(userKeys.userProfile(), response.data) //!<< this really needs to be checked
  }

  // if (!response?.data?.success) {
  // toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
  // } else if (response?.data?.success) {
  //   toast.success('Password changed.')
  // } //!the API isn't set up to give a success message yet. // OLDer CODE NOTES

  return response.data
}

export const submitRequestForm = async (variables: ISubmitRequestForm) => {
  const { values, setIsFormSubmitted, queryClient } = variables
  const headersValue: AxiosRequestConfig = { headers: authHeader() }
  const response = await axios.post(
    `${PUBLIC_API_URL}customer/submit-request-form`,
    values,
    headersValue,
  )

  if (!response?.data?.success) {
    toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
  } else if (response?.data?.success) {
    toast.success(
      `${response?.data?.success_message.join(' ') || 'Request submitted.'}`,
      { autoClose: 12000, pauseOnHover: true, position: 'top-center' },
    ) // custom success toast for forms since pertinent details are returned
    setIsFormSubmitted(true)
    if (response.data.customer) {
      queryClient.setQueryData(userKeys.customer(), response.data.customer)
    }
    queryClient.setQueryData(userKeys.user, response.data.user)
  }

  return response.data
}

export const switchToSilver = async (queryClient: QueryClient) => {
  const headersValue: AxiosRequestConfig = { headers: authHeader() }
  const response = await axios.post(
    `${API_URL}customers/update-to-silver`,
    null,
    headersValue,
  )

  if (!response?.data?.success) {
    toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
  } else if (response?.data?.success) {
    toast.success('Pricing Level Updated.')
    if (response.data.customer) {
      queryClient.setQueryData(userKeys.customer(), response.data.customer)
    }
    queryClient.setQueryData(userKeys.user, response.data.user)
  }

  return response.data
}

export const loginUser = async (variables: ILoginData) => {
  const { email, password, otp, customers_id, is_impersonate, queryClient } =
    variables

  const response = await axios.post(`${AUTH_URL}login`, {
    username: email,
    password: password,
    customers_id: customers_id,
    otp: otp,
    is_impersonate: is_impersonate,
    project_id: PROJECT_ID,
  })

  if (!response?.data?.success) {
    const toastMsg =
      response.data.errors.join(' ') === 'Unauthorized'
        ? 'Your password and email do not match.'
        : response.data.errors.join(' ')
    toast.error(`${toastMsg || 'An error occurred.'}`)
    queryClient.setQueryData(userKeys.user, null)
    queryClient.setQueryData(userKeys.customer(), null)
    queryClient.setQueryData(userKeys.status(), false)

    // clean client
    localStorage.removeItem('user')
    document.cookie = `document_token= ; path=/; max-age=-1;`
  } else if (response?.data?.success) {
    queryClient.setQueryData(userKeys.user, response.data.user)
    queryClient.setQueryData(userKeys.customer(), response.data.customer)
    queryClient.setQueryData(userKeys.status(), true)
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data))
      document.cookie = `document_token=${response.data.document_token}; path=/`
    }
    queryClient.invalidateQueries(userKeys.userProfile())
    queryClient.removeQueries(filterKeys.sortOptions())
  }
  return response.data
}

export const logoutUser = async (variables: IQueryClient) => {
  const { queryClient } = variables

  const headersValue: AxiosRequestConfig = { headers: authHeader() }

  const response = await axios.post(`${AUTH_URL}logout`, null, headersValue)

  if (!response || response?.status !== 200) {
    toast.error(`${'An error occurred.'}`)

    // log them out client-side anyway
    queryClient.setQueryData(userKeys.user, null)
    queryClient.setQueryData(userKeys.customer(), null)
    queryClient.setQueryData(userKeys.status(), false)
    queryClient.setQueryData(userKeys.userProfile(), null)

    queryClient.removeQueries(documentKeys.accountDocuments)

    // user context's initializeUserState called in mutation call
    // product context's initializeProductState called in mutation call

    // clean client
    localStorage.removeItem('user')
    document.cookie = `document_token= ; path=/; max-age=-1;`
  } else {
    toast.success(`${response?.data?.message || 'Success.'}`)

    queryClient.setQueryData(userKeys.user, null)
    queryClient.setQueryData(userKeys.customer(), null)
    queryClient.setQueryData(userKeys.status(), false)
    queryClient.setQueryData(userKeys.userProfile(), null)

    queryClient.removeQueries(documentKeys.accountDocuments)
    queryClient.removeQueries(filterKeys.sortOptions())

    // user context's initializeUserState called in mutation call
    // product context's initializeProductState called in mutation call

    localStorage.removeItem('user')
    document.cookie = `document_token= ; path=/; max-age=-1;`
  }
  return response
}

export const registerUser = async (variables: IRegisterUser) => {
  const { registerData, queryClient } = variables

  const headersValue: AxiosRequestConfig = { headers: authHeader() }

  const response = await axios.post(
    `${AUTH_URL}register`,
    { registerData: registerData, project_id: PROJECT_ID },
    headersValue,
  )

  if (!response?.data?.success) {
    toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
  } else if (response?.data?.success) {
    queryClient.setQueryData(userKeys.user, response.data.user)
    queryClient.setQueryData(userKeys.customer(), response.data.customer)
    queryClient.setQueryData(userKeys.status(), true)
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data))
      document.cookie = `document_token=${response.data.document_token}; path=/` // todo: confirm that this, and the above data is actually coming back in request response
    }

    queryClient.invalidateQueries(userKeys.userProfile())

    const registerSuccessToastText =
      registerData?.request_volume === true
        ? 'Registration complete! Taking you to the Volume Application Form.'
        : 'Registration complete! Taking you to the main page.'

    toast.success(registerSuccessToastText)
  }

  return response
}

export const useGetCustomerPreviousOrders = (
  limit?: number,
  statuses?: Array<number>,
) => {
  const selectStatuses = statuses ? statuses : orderStatusTypePreviousIds
  return useQuery({
    queryKey: userKeys.getCustomerPreviousOrders,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CUSTOMERS_URL}select-orders`,
        {
          limit: limit,
          statuses: selectStatuses,
        },
        headersValue,
      )
      return response.data
    },
  })
}

export const useLoadAvailablePickupDates = () => {
  return useQuery({
    queryKey: userKeys.loadAvailablePickupDates,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CUSTOMERS_URL}load-available-pickup-dates`,
        null,
        headersValue,
      )
      return response.data
    },
  })
}

export const fetchAvailablePickupTimes = async (selectedDate: string) => {
  const headersValue: AxiosRequestConfig = { headers: authHeader() }
  const response = await axios.post(
    `${API_CUSTOMERS_URL}load-available-pickup-times`,
    { selectedDate: selectedDate },
    headersValue,
  )
  return response.data
}

export const useLoadAvailableShipDates = () => {
  return useQuery({
    queryKey: userKeys.loadAvailableShipDates,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CUSTOMERS_URL}load-available-ship-dates`,
        null,
        headersValue,
      )
      return response.data
    },
  })
}
