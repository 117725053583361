import {
  GET_DESKTOP_MENU_ITEMS,
  GET_HAMBURGER_MENU_ITEMS,
  GET_STORE_SETTINGS,
  GET_APP_INFO,
  SET_APP_VERSION_CURRENT,
  GET_AUTHORIZATION_DOC_TYPES,
  GET_INSURANCE_DOC_TYPES,
  GET_APP_SETTINGS,
  TOGGLE_PRODUCT_LIST_DETAILS,
  SET_MAIN_HEADER_DIV_HEIGHT,
  SET_MOBILE_MAIN_HEADER_DIV_HEIGHT,
  SET_FLASH_SALE_BANNER_DESKTOP,
  SET_FLASH_SALE_BANNER_MOBILE,
  SET_SHOW_FLASH_SALE_BANNER,
  SET_IS_DOWN_FOR_MAINTENANCE,
  GET_PAGE_SEO,
  SET_IDZ_CUSTOM_DATA,
} from './constants'
import { ISiteAction, ISiteState } from './types'

export default function siteReducer(state: ISiteState, action: ISiteAction) {
  const payload = action.payload

  switch (action.type) {
    case GET_DESKTOP_MENU_ITEMS:
      return {
        ...state,
        desktopMenuItems: payload,
      }
    case GET_HAMBURGER_MENU_ITEMS:
      return {
        ...state,
        hamburgerMenuItems: payload,
      }
    case GET_STORE_SETTINGS:
      return {
        ...state,
        storeSettings: payload,
      }
    case GET_APP_INFO:
      return {
        ...state,
        appInfo: payload,
      }
    case SET_APP_VERSION_CURRENT:
      return {
        ...state,
        appVersionCurrent: payload,
      }
    case GET_AUTHORIZATION_DOC_TYPES:
      return {
        ...state,
        authorizationDocTypes: payload,
      }
    case GET_INSURANCE_DOC_TYPES:
      return {
        ...state,
        insuranceDocTypes: payload,
      }
    case GET_APP_SETTINGS:
      return {
        ...state,
        appSettings: payload,
      }

    case TOGGLE_PRODUCT_LIST_DETAILS:
      return {
        ...state,
        showProductListDetails: !state.showProductListDetails,
      }

    case SET_MAIN_HEADER_DIV_HEIGHT:
      return {
        ...state,
        mainHeaderDivHeight: payload,
      }

    case SET_MOBILE_MAIN_HEADER_DIV_HEIGHT:
      return {
        ...state,
        mobileMainHeaderDivHeight: payload,
      }

    case SET_FLASH_SALE_BANNER_DESKTOP:
      return {
        ...state,
        flashSaleBannerDesktop: payload,
      }

    case SET_FLASH_SALE_BANNER_MOBILE:
      return {
        ...state,
        flashSaleBannerMobile: payload,
      }

    case SET_SHOW_FLASH_SALE_BANNER:
      return {
        ...state,
        showFlashSaleBanner: payload,
      }

    case SET_IS_DOWN_FOR_MAINTENANCE:
      return {
        ...state,
        isDownForMaintenance: payload,
      }

    case GET_PAGE_SEO:
      return {
        ...state,
        pageSeo: payload,
      }

    case SET_IDZ_CUSTOM_DATA:
      return {
        ...state,
        idzCustomData: payload,
      }

    default:
      return state
  }
}
