import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import RwIconTextLink from '../../molecules/RwIconTextLink'
import RwIcon from '../../molecules/RwIcon'

const AccountDropdownItems: React.FC = () => {
  return (
    <>
      <RwIconTextLink
        containerClassProps=""
        textProps="Orders"
        toLinkProps="/account/orders"
        icon={<RwIcon icon="TruckIcon" />}
      />

      <RwIconTextLink
        containerClassProps=""
        textProps="Carts"
        toLinkProps="/account/carts"
        icon={<RwIcon icon="BookmarkIcon" />}
      />

      <RwIconTextLink
        containerClassProps=""
        textProps="Profile"
        toLinkProps="/account/profile"
        icon={<RwIcon icon="AddressCardIcon" />}
      />

      <NavDropdown.Divider />

      <RwIconTextLink
        containerClassProps=""
        textProps="Password"
        toLinkProps="/account/password"
        icon={<RwIcon icon="KeyIcon" />}
      />
      <RwIconTextLink
        containerClassProps=""
        textProps="Preferences"
        iconDetails="fa-solid fa-envelope-circle-check"
        toLinkProps="/account/preferences"
        icon={<RwIcon icon="PreferencesIcon" />}
      />
      <RwIconTextLink
        containerClassProps=""
        textProps="Documents"
        iconDetails="fa-solid fa-file-text"
        toLinkProps="/account/documents"
        icon={<RwIcon icon="DocumentIcon" />}
      />
      <RwIconTextLink
        containerClassProps=""
        textProps="Downloads"
        iconDetails="fa-solid fa-file-text"
        toLinkProps="/account/downloads"
        icon={<RwIcon icon="DocumentDownloadIcon" />}
      />

      <NavDropdown.Divider />
    </>
  )
}

export default AccountDropdownItems
