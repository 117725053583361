import { Link } from 'react-router-dom'
import { Offcanvas, Button } from 'react-bootstrap'
import { SHOP_LIST_PATH } from '../../../constants/constants'
import WholesaleInfo from '../../molecules/Site/WholesaleInfo'
import ProLineSideNavMenuItems from '../../molecules/Shop/ProLineSideNavMenuItems'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../context/user/UserProvider'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'

interface IProLineOffcanvasProps {
  show: boolean
  handleClose: () => void
}

const ProLineOffcanvas: React.FC<IProLineOffcanvasProps> = ({
  show,
  handleClose,
}) => {
  const navigate = useNavigate()
  const { isDarkMode, setDarkMode } = useUserContext()

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode)
  }

  return (
    <Offcanvas show={show} onHide={handleClose} scroll={true}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Button
            variant="danger"
            onClick={() => navigate(SHOP_LIST_PATH)}
            className="m-3"
          >
            Shop All Products
          </Button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <ProLineSideNavMenuItems handleClick={handleClose} />
        <Button
          variant={isDarkMode ? 'secondary' : 'primary'}
          onClick={toggleDarkMode}
          className="ms-3 mt-3"
        >
          <span>{isDarkMode ? <BsSunFill /> : <BsFillMoonFill />}</span>
          <span className={'ms-2'}>
            {isDarkMode ? 'Disable ' : 'Enable'} Dark Mode
          </span>
        </Button>
        <WholesaleInfo />
        <div className="ps-3 mt-4">
          <Link to="/contact" onClick={handleClose}>
            Contact Us
          </Link>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default ProLineOffcanvas
