import { ICartFilter } from '../../context/products/types'

export const bannerKeys = {
  all: ['banners'] as const,
  loggedIn: () => [...bannerKeys.all, 'loggedIn'] as const,
  loggedOut: () => [...bannerKeys.all, 'loggedOut'] as const,
  prolineAboutPage: () => [...bannerKeys.all, 'prolineAboutPage'] as const,
}

export const featuredTilesKeys = {
  all: ['featuredTiles'] as const,
  featuredTiles: () => [...featuredTilesKeys.all] as const,
}

export const landingPageSavedCartsKeys = {
  all: ['landingPageSavedCarts'] as const,
  savedCarts: (filter?: ICartFilter, limit?: number) =>
    [...landingPageSavedCartsKeys.all, limit, filter] as const,
}

export const landingPageOpenOrdersKeys = {
  all: ['landingPageOpenOrders'] as const,
  openOrders: (limit?: number, statuses?: Array<number>) =>
    [...landingPageOpenOrdersKeys.all, limit, statuses] as const,
}

export const activeRedirectsKeys = {
  all: ['activeRedirects'] as const,
  activeRedirects: () => [...activeRedirectsKeys.all] as const,
}

export const socialMediaKeys = {
  all: ['socialMediaKeys'] as const,
  socialMedia: () => [...socialMediaKeys.all] as const,
}

export const marketingPageKeys = {
  all: ['marketingPages'] as const,
  marketingPages: () => [...marketingPageKeys.all] as const,
  marketingPage: (url: string, preview: string | null) =>
    [...marketingPageKeys.all, url, preview] as const,
}

export const marketingPostKeys = {
  all: ['marketingPosts'] as const,
  marketingPosts: () => [...marketingPostKeys.all] as const,
  marketingPost: (url: string, preview: string | null) =>
    [...marketingPostKeys.all, url, preview] as const,
}

export const marketingPageStageRouteKeys = {
  all: ['marketingPageStageRoutes'] as const,
  marketingPageStageRoutes: () => [...marketingPageStageRouteKeys.all] as const,
}

export const marketingPostStageRouteKeys = {
  all: ['marketingPostStageRoutes'] as const,
  marketingPostStageRoutes: () => [...marketingPostStageRouteKeys.all] as const,
}
