import React from 'react'
import { Link } from 'react-router-dom'
import SideNavMenu from './SideNavMenu'
import { Nav, Stack } from 'react-bootstrap'
import {
  SHOP_LIST_PATH,
  showAboveBreakpoint,
} from '../../../constants/constants'
import { useProductListsData } from '../../../queries/filters'
import history from '../../../context/history'
import { useUserContext } from '../../../context/user/UserProvider'
import { isProline } from '../../../helpers'
import { useProductContext } from '../../../context/products/ProductProvider'

const BottomHeader: React.FC = () => {
  const { data: productLists } = useProductListsData()
  const { isDarkMode } = useUserContext()
  const { setPage } = useProductContext()

  const handleClick = (e: any) => {
    setPage(1)
    const listItemId: string = e.target.id
    history.push(`${listItemId}`)
  }

  return (
    <div className={`border-top mx-1 pe-3 ${showAboveBreakpoint}`}>
      <Stack direction="horizontal">
        <SideNavMenu />
        <div>
          <div className="product_lists_nav_bar ms-3">
            <Nav>
              {productLists?.map((list: any, index: number) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    className={`${isDarkMode ? 'text-light' : ''}`}
                    as={Link}
                    to={`/${SHOP_LIST_PATH}/l/${list.list_url}`}
                    id={`/${SHOP_LIST_PATH}/l/${list.list_url}`}
                    onClick={handleClick}
                    data-bs-value={list.value}
                  >
                    {list.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
              <Nav.Item>
                <Nav.Link
                  className={`${isDarkMode ? 'text-light' : ''}`}
                  as={Link}
                  to={`/${SHOP_LIST_PATH}`}
                  id={`/${SHOP_LIST_PATH}`}
                  onClick={handleClick}
                >
                  All Products
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={isProline() ? '/contact' : '//76fireworks.com/contact'}
                  target={isProline() ? '_self' : '_blank'}
                  className={`${isDarkMode ? 'text-light' : ''}`}
                >
                  Contact Us
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </Stack>
    </div>
  )
}

export default BottomHeader
