import React, { useEffect } from 'react'
import {
  useCategoriesData,
  useManufacturersData,
  useProductListsData,
  useColorsData,
  useEffectsData,
  useFiringPatternsData,
  useDebrisProfilesData,
  useCaliberData,
  usePerformanceHeightsData,
  usePriceFiltersData,
  useDurationsData,
  useIgnitionTypesData,
} from '../../../queries/filters'
import history from '../../../context/history'
import { userKeys } from '../../../queries/user/keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { IUserData } from '../../../queries/user/types'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { isMobile } from '../../atoms/responsive'
import { useUserContext } from '../../../context/user/UserProvider'
import { useProductContext } from '../../../context/products/ProductProvider'
import ProLineNavSection from './ProLineNavSection'
import {
  PRODUCT_QUERY_CATEGORIES,
  PRODUCT_QUERY_BRANDS,
  PRODUCT_QUERY_COLORS,
  PRODUCT_QUERY_EFFECTS,
  PRODUCT_QUERY_FIRING_PATTERNS,
  PRODUCT_QUERY_DEBRIS_PROFILES,
  PRODUCT_QUERY_CALIBERS,
  PRODUCT_QUERY_PERFORMANCE_HEIGHTS,
  PRODUCT_QUERY_PRICE,
  PRODUCT_QUERY_LISTS,
  PRODUCT_QUERY_SEARCH,
  PRODUCT_QUERY_PAGE,
  PRODUCT_QUERY_DURATIONS,
  PRODUCT_QUERY_IGNITION_TYPES,
} from '../../../constants/constants'

interface ISideNavMenuProps {
  handleClick?: () => void | null
}

const ProLineSideNavMenuItems: React.FC<ISideNavMenuProps> = ({
  handleClick,
}) => {
  const { isDarkMode } = useUserContext()
  const { setPage } = useProductContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const { getDesktopMenuItems, getHamburgerMenuItems } = useSiteContext()
  const status = queryClient.getQueryData(userKeys.status()) as boolean

  const { data: categories } = useCategoriesData()
  const { data: manufacturers } = useManufacturersData(
    new URLSearchParams(window.location.search).get(PRODUCT_QUERY_SEARCH),
    user?.email,
  )
  const { data: productLists } = useProductListsData()
  const { data: durations } = useDurationsData()
  const { data: ignitionTypes } = useIgnitionTypesData()
  const { data: colors } = useColorsData()
  const { data: effects } = useEffectsData()
  const { data: firingPatterns } = useFiringPatternsData()
  const { data: debrisProfiles } = useDebrisProfilesData()
  const { data: calibers } = useCaliberData()
  const { data: performanceHeights } = usePerformanceHeightsData()
  const { data: priceFilters } = usePriceFiltersData(status)

  useEffect(() => {
    !isMobile && getDesktopMenuItems()
    getHamburgerMenuItems()
  }, [])

  const onClickQuickLink = (e: any) => {
    setPage(1)
    history.push(e.target.id)
    handleClick?.()
  }

  const sections = [
    {
      title: 'Categories',
      data: categories,
      queryType: PRODUCT_QUERY_CATEGORIES,
      useQueryParams: false,
    },
    {
      title: 'Brands',
      data: manufacturers,
      queryType: PRODUCT_QUERY_BRANDS,
      useQueryParams: false,
    },
    {
      title: 'Colors',
      data: colors,
      queryType: PRODUCT_QUERY_COLORS,
      useQueryParams: true,
    },
    {
      title: 'Effects',
      data: effects,
      queryType: PRODUCT_QUERY_EFFECTS,
      useQueryParams: true,
    },
    {
      title: 'Firing Patterns',
      data: firingPatterns,
      queryType: PRODUCT_QUERY_FIRING_PATTERNS,
      useQueryParams: true,
    },
    {
      title: 'Debris Profiles',
      data: debrisProfiles,
      queryType: PRODUCT_QUERY_DEBRIS_PROFILES,
      useQueryParams: true,
    },
    {
      title: 'Calibers',
      data: calibers,
      queryType: PRODUCT_QUERY_CALIBERS,
      useQueryParams: true,
    },
    {
      title: 'Performance Heights',
      data: performanceHeights,
      queryType: PRODUCT_QUERY_PERFORMANCE_HEIGHTS,
      useQueryParams: true,
    },
    {
      title: 'Durations',
      data: durations,
      queryType: PRODUCT_QUERY_DURATIONS,
      useQueryParams: true,
    },
    {
      title: 'Ignition Types',
      data: ignitionTypes,
      queryType: PRODUCT_QUERY_IGNITION_TYPES,
      useQueryParams: true,
    },
    ...(status
      ? [
          {
            title: 'Price',
            data: priceFilters,
            queryType: PRODUCT_QUERY_PRICE,
            useQueryParams: true,
          },
        ]
      : []),
    {
      title: 'Lists',
      data: productLists,
      queryType: PRODUCT_QUERY_LISTS,
      useQueryParams: false,
    },
  ]

  return (
    <div className="border-top">
      {sections.map((section) => (
        <ProLineNavSection
          key={section.title}
          title={section.title}
          data={section.data}
          queryType={section.queryType} // Only used for legacy paths
          isDarkMode={isDarkMode}
          onClickQuickLink={onClickQuickLink}
          useQueryParams={section.useQueryParams}
        />
      ))}
    </div>
  )
}

export default ProLineSideNavMenuItems
